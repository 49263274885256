import React from "react";
import { motion } from "framer-motion";

function Navbar({
  defaultAccount,
  balance,
  network,
  chainId,
  currencySymbol,
  connectWalletHandler,
  disconnectWalletHandler,
  setSelectedWallet,
}) {
  const handleConnect = () => {
    if (defaultAccount) {
      disconnectWalletHandler();
    } else {
      connectWalletHandler();
    }
  };

  // Function to format the Ethereum address
  const formatAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Function to format the balance with symbol and decimals
  const formatBalance = (balance, symbol) => {
    return `${parseFloat(balance).toFixed(4)} ${symbol}`; // Limiting balance to 4 decimal places
  };

  return (
    <div className="w-full bg-gray-800 text-white flex items-center py-5 px-8 shadow-2xl rounded-lg">
      <div className="w-full flex justify-between items-center">
        <div className="text-3xl font-bold text-white">
          {/* Animated "Chain Abstraction" text with bounce and fade-in effect */}
          <motion.div
            initial={{ opacity: 0, y: -50 }} // Starts slightly above and invisible
            animate={{ opacity: 1, y: 0 }} // Moves to its position and fades in
            transition={{ duration: 1.5, ease: "easeOut" }} // Smooth transition
            whileHover={{ scale: 1.1, rotate: 5 }} // Slightly scale and rotate on hover
          >
           Universal Blockchain Passport
          </motion.div>
        </div>

        <div className="flex items-center">
          {defaultAccount ? (
            <div className="flex flex-col items-end mr-4">
              {/* <div className="text-sm text-gray-300 mb-1">
                Balance: {formatBalance(balance, currencySymbol)}
              </div> */}
              <div className="text-sm text-gray-300 mb-1">
                Address: {formatAddress(defaultAccount)}
              </div>
              {/* <div className="text-sm text-gray-300">Network: {network}</div> */}
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded mt-2 transition-transform transform hover:translate-y-[-2px]"
                onClick={handleConnect}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <div>
              {/* <select
                onChange={(e) => setSelectedWallet(e.target.value)}
                className="bg-gray-700 text-white rounded p-2"
              >
                <option value="metamask">MetaMask</option>
                <option value="unisat">Unisat</option>
              </select> */}
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition-transform transform hover:translate-y-[-2px]"
                onClick={handleConnect}
              >
                Connect Wallet
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
