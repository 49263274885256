import { ApiPromise, HttpProvider } from "@polkadot/api";
import bigInt from "big-integer";

export async function submitTransactionIntent(
    fromAddress,
    toAddress,
    fromChainId,
    toChainId,
    amount,
    fromTokenInfo,
    toTokenInfo,
    path,
    signerAddress,
    defaultAccount
) {
    try {
        const provider = new HttpProvider("https://chain.antiers.work");
        const api = await ApiPromise.create({ provider });

        let toAmount =
            toChainId === "0"
                ? bigInt(Math.round(amount * 100000000))
                : bigInt(Math.round(amount * 1000000000000000000));

        console.log("Submitting transaction intent with parameters:");
        console.log("From Address:", fromAddress);
        console.log("To Address:", toAddress);
        console.log("From Chain ID:", fromChainId);
        console.log("To Chain ID:", toChainId);
        console.log("Amount:", toAmount.toString()); // Ensure BigInt is logged as a string
        console.log("From Token Address:", fromTokenInfo?.address || "N/A");
        console.log("To Token Address:", toTokenInfo?.address || "N/A");
        console.log("Transaction Path:", path);

        console.log("also From Token info:", fromTokenInfo);
        console.log("also To Token info:", toTokenInfo);

        const extrinsic = await api.tx.intents.depositIntent(
            fromAddress,
            toAddress,
            fromChainId,
            toChainId,
            toAmount,
            fromTokenInfo.address,
            toTokenInfo.address,
            path
        );

        console.log("Signing and sending transaction...");

        const extrinsicTx = await extrinsic.signAndSend(
            signerAddress,
            { signer: defaultAccount.signer },
            ({ status }) => {
                if (status.isInBlock) {
                    console.log(`Transaction included in block: ${status.asInBlock}`);
                }
            }
        );

        console.log("Respnse from extransic : intents.depositIntent is :",extrinsicTx);
        

        const nonceKey = [signerAddress, api.createType("Bytes", path)];
        const nonce = await api.query.intents.nonce(nonceKey);

        console.log("Nonce retrieved:", nonce.toString());
        return { api, nonce };

    } catch (error) {
        console.error("Error submitting transaction:", error);
        throw error;
    }
}
