export const chains = [
  // { id: "", name: "", explorer: "" },
  { id: "997", name: "5ireChain Thunder Testnet", explorer: "https://testnet.5irescan.io" },
  // { id: "11155111", name: "Sepolia", explorer: "https://sepolia.etherscan.io" },
  { id: "9007", name: "Shido Testnet", explorer: "https://testnet.shidoscan.com" },
  // { id: "601", name: "Vine Testnet", explorer: "" },
  // { id: "1404", name: "Vine Mainnet", explorer: "" },
  // { id: "995", name: "5ireChain Mainnet", explorer: "https://explorer.5ire.network" },
  // { id: "17000", name: "Holesky", explorer: "https://holesky.etherscan.io" },
  { id: "0", name: "BTC Testnet", explorer: "https://blockstream.info/testnet" },
  // { id: "129", name: "Saita Testnet", explorer: "" },
];


// Tokens mapped to each chain including native currencies
export const tokens = {
  9007: [
    { symbol: "SHIDO", address: null }, // Native token for SHIDO chain
    // { symbol: "WETH", address: "0xDE2de0284Deace60f1C84e60a31DFcb9BC61f185" },
    { symbol: "W5IRE", address: "0x11B896DD27F8CFD0dFbe7eDA979194c2779B647F" },
    { symbol: "WBTC", address: "0xAB02d6148a1d1D256106e5A4c3478920aa6acC14" },

  ],
  997: [
    { symbol: "5IRE", address: null }, // Native token for 5ire chain
    // { symbol: "WETH", address: "0x2Dc2D212843529F14c907A0b81a54f58C1f76018" },
    { symbol: "WSHIDO", address: "0x23BA5C4F55Ad2F56D3F8817B62917c03D43650Ba" },
    { symbol: "WBTC", address: "0x90789eD2B256C1495BBFC96FfC8411D300Ed1c91" },


    // { symbol: "USDC", address: "0x..." },
    // { symbol: "WSTC", address: "0xe557247847AD3120D1D8972D77fc09984C3D635b" },
  ],
  // 11155111: [
  //   { symbol: "ETH", address: null }, // Native token for Vine chain
  //   // { symbol: "W5IRE", address: "" },
  //   // { symbol: "WSHIDO", address: "" },
  // ],
  // 17000: [
  //   { symbol: "ETH", address: null }, // Native token for Holesky
  //   { symbol: "USDC", address: "0x..." },
  //   { symbol: "W5IRE", address: "0xB75b0aFA24D401bDF1fa5f6c369c23E793655F26" },
  //   { symbol: "WBTC", address: "0x60cF746F27D2c0BAe0eFF3bB99B9c6D466e2f2C4" },
  // ],
  // 601: [
  //   { symbol: "VNE", address: null }, // Native token for Vine chain
  //   { symbol: "W5IRE", address: "0x431Bd997875008F75AD6510879d56c792D7FD83e" },
  //   { symbol: "WSTC", address: "0xDD80848D46066BB108DBe1C1097b4362219136cF" },
  // ],
  0: [
    { symbol: "BTC", address: null },  // Native token for BTC chain
  ],

  // "129": [
  //   { symbol: "SAITA", address: null },  // Native token for Saita chain
  //   { symbol: "W5IRE", address: "0x03FcEd1D0066E4A178Ecd66D2D645c1D8493799D" }
  // ],

  // Add more chains and their respective tokens/native currencies
};

export const CONFIG = {
  API_KEY: "your_api_key_here",
  // BASE_URL: "http://13.251.179.90:4000",
  BASE_URL: "https://api.abstraction.antiers.work"

  
};
