import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserProvider, formatUnits } from "ethers";
import SwapForm from "./components/SwapForm";
import Navbar from "./components/Navbar";
import MintPage from "./components/MintPage";
import { getWalletBySource } from "@talismn/connect-wallets";

let provider;

if (window.ethereum && window.ethereum.isMetaMask) {
  provider = new BrowserProvider(window.ethereum);
} else {
  console.error("MetaMask is not installed!");
}

const fetchNetworkDataFromAPI = async (chainId) => {
  try {
    const response = await fetch("https://chainid.network/chains.json");
    const chains = await response.json();

    let chainIdInt;
    if (chainId <= Number.MAX_SAFE_INTEGER) {
      chainIdInt = Number(chainId);
    } else {
      throw new Error("chainId is too large to convert safely to a Number.");
    }

    const chainData = chains.find((chain) => chain.chainId === chainIdInt);

    if (chainData) {
      return {
        name: chainData.name,
        symbol: chainData.nativeCurrency?.symbol || "ETH",
        decimals: chainData.nativeCurrency?.decimals || 18,
      };
    } else {
      console.error(`Network not found for Chain ID: ${chainId}.`);
      return {
        name: `Network not found in Chainlist for Chain ID: ${chainId}`,
        symbol: "ETH",
        decimals: 18,
      };
    }
  } catch (error) {
    console.error("Error fetching chain data:", error);
    return {
      name: `Error fetching network for Chain ID: ${chainId}`,
      symbol: "ETH",
      decimals: 18,
    };
  }
};

function App() {
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [balance, setBalance] = useState(null);
  const [network, setNetwork] = useState(null);
  const [chainId, setChainId] = useState("997");
  const [currencySymbol, setCurrencySymbol] = useState("ETH");
  const [decimals, setDecimals] = useState(18);
  const [selectedWallet, setSelectedWallet] = useState("metamask");
  const [accounts, setAccounts] = useState([]);
  const talismanWallet = getWalletBySource("talisman");

  const connectTalismanWallet = async () => {
    if (!talismanWallet) {
      alert("Talisman wallet not found! Please install it.");
      return;
    }

    try {
      await talismanWallet.enable("chain-abstraction");
      setSelectedWallet(talismanWallet.title);

      const unsubscribe = await talismanWallet.subscribeAccounts(
        (walletAccounts) => {
          setAccounts(walletAccounts);
        }
      );

      return () => unsubscribe();
    } catch (err) {
      console.error("Error connecting to Talisman:", err);
    }
  };

  useEffect(() => {
    if (!accounts.length) return;
    setDefaultAccount(accounts[0]);
    console.log(accounts);
  }, [accounts]);

  const disconnectWalletHandler = () => {
    setDefaultAccount(null);
    setBalance(null);
    setNetwork(null);
    setChainId(null);
    setCurrencySymbol("ETH");
    setDecimals(18);
  };

  return (
    <Router>
      <div className="max-w-7xl mx-auto p-10 pt-20">
        <Navbar
          defaultAccount={defaultAccount?.address}
          balance={balance}
          network={network}
          chainId={chainId}
          currencySymbol={currencySymbol}
          connectWalletHandler={connectTalismanWallet}
          disconnectWalletHandler={disconnectWalletHandler}
          setSelectedWallet={setSelectedWallet}
        />

        <Routes>
          <Route
            path="/"
            element={
              <header className="bg-white p-10 rounded-lg shadow-xl mt-5">
                <SwapForm
                  defaultAccount={defaultAccount}
                  onAccountChange={setDefaultAccount}
                  network={network}
                  chainId={chainId}
                />
              </header>
            }
          />
          <Route
            path="/mint"
            element={
              <MintPage
                defaultAccount={defaultAccount}
                network={network}
                chainId={chainId}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
