import React, { useState, useEffect } from "react";
import {
  checkServerAvailability,
  generateEvmAddress,
  generateBtcAddress,
} from "../utils/mpcUtils.js";
import { submitTransactionIntent } from "../utils/chain.js";
import { checkIntentStatus } from "../utils/solver.js";
import { tokens, chains } from "../utils/constants";

// Form field component
const FormField = ({
  label,
  id,
  value,
  onChange,
  disabled,
  placeholder,
  type = "text",
}) => (
  <div className="flex flex-col gap-2">
    <label htmlFor={id} className="font-semibold text-gray-700">
      {label}:
    </label>
    <input
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
      className="p-3 border border-gray-300 rounded-lg text-lg w-full"
    />
  </div>
);

// Dropdown select component for chains and tokens
const SelectField = ({ label, id, options, value, onChange, disabled }) => (
  <div className="flex flex-col gap-2">
    <label htmlFor={id} className="font-semibold text-gray-700">
      {label}:
    </label>
    <select
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="p-3 border border-gray-300 rounded-lg text-lg w-full"
    >
      <option value="" >
        Select {label}
      </option>
      {options.map((option) => (
        <option
          key={option.id || option.symbol}
          value={option.id || option.symbol}
        >
          {option.name || option.symbol}
        </option>
      ))}
    </select>
  </div>
);

const SwapButton = ({ isProcessing, status, retry }) => (
  <button
    type="submit"
    className={`p-4 text-lg font-semibold text-white rounded-lg transition-all w-full ${status === "success"
      ? "bg-green-500 cursor-not-allowed"
      : retry
        ? "bg-yellow-500 hover:bg-yellow-600"
        : "bg-blue-600 hover:bg-blue-800"
      } ${isProcessing ? "bg-gray-400 cursor-not-allowed" : ""}`}
    disabled={isProcessing || status === "success"}
  >
    {isProcessing
      ? "Processing..."
      : status === "success"
        ? "Transfer Successful"
        : retry
          ? "Retry"
          : "Transfer"}
  </button>
);

const StatusMessage = ({
  statusMessage,
  transactionHash,
  mpcResponceHash,
  fromChain,
  toChain,
  fromChainExp,
  toChainExp,
}) => (
  <div className="mt-5">
    {statusMessage && (
      <p className="font-bold text-gray-800 mt-2">{statusMessage}</p>
    )}
    {transactionHash && mpcResponceHash && (
      <div className="mt-3 p-3 bg-gray-100 rounded-lg">
        <p className="font-medium">{fromChain} Transaction :</p>
        <a
          href={`${fromChainExp}/tx/${transactionHash}`}
          target="_blank"
          rel="noopener noreferrer"
          className="block break-all text-blue-600 hover:underline"
        >
          {fromChainExp}/tx/{transactionHash}
        </a>
      </div>
    )}
    {mpcResponceHash && (
      <div className="mt-3 p-3 bg-gray-100 rounded-lg">
        <p className="font-medium">{toChain} Transaction :</p>
        <a
          href={`${toChainExp}/tx/${mpcResponceHash}`}
          target="_blank"
          rel="noopener noreferrer"
          className="block break-all text-blue-600 hover:underline"
        >
          {toChainExp}/tx/{mpcResponceHash}
        </a>
      </div>
    )}
  </div>
);
function SwapForm({ defaultAccount, network, chainId }) {
  const [fromChainId, setFromChainId] = useState(chainId);
  const [toChainId, setToChainId] = useState("997");
  const [fromTokenSymbol, setFromTokenSymbol] = useState("");
  const [toToken, setToToken] = useState("");
  const [amount, setAmount] = useState("");
  const [fromAddress, setFromAddress] = useState(defaultAccount?.address || "");
  const [toAddress, setToAddress] = useState("");
  const [signerAddress, setSignerAddress] = useState(defaultAccount?.address || "");
  const [statusMessage, setStatusMessage] = useState("");
  const [mpcResponceHash, setmpcResponceHash] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState("");
  const [retry, setRetry] = useState(false);
  const [fromChain, setFromChain] = useState(
    network || "5ireChain Thunder Testnet"
  );
  const [toChain, setToChain] = useState("5ireChain Thunder Testnet");
  const [fromChainExp, setFromChainExp] = useState(
    "https://testnet.5irescan.io"
  );
  const [toChainExp, setToChainExp] = useState("https://testnet.5irescan.io");
  const [fromToken, setFromToken] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [myAdress, setMyaddress] = useState("")

  useEffect(() => {
    if (!defaultAccount?.address) return;

    setSignerAddress(defaultAccount?.address)

    const updateAddress = async (chainId, setAddress) => {
      if (!+chainId) {
        const generatedMPCAddress = await generateBtcAddress(defaultAccount.address);
        setAddress(generatedMPCAddress);
      } else {
        const generatedMPCAddress = await generateEvmAddress(chainId, defaultAccount.address);
        setAddress(generatedMPCAddress);
      }
    };

    // Update "from" address
    updateAddress(fromChainId, setFromAddress);

    // Update "to" address only if "My Address" checkbox is checked
    if (myAdress) {
      updateAddress(toChainId, setToAddress);
    } else {
      setToAddress(defaultAccount.address);
    }

  }, [fromChainId, toChainId, myAdress, defaultAccount?.address]);


  const handleChainChange = (e, chainType) => {
    const chainId = e.target.value;
    const selectedChain = chains.find((chain) => chain.id === chainId);
    const tokenOptions = tokens[chainId] || [];

    if (chainType === "from") {
      setFromChainId(chainId);
      setFromChain(selectedChain.name);
      setFromChainExp(selectedChain.explorer);
      setFromToken(tokenOptions.length ? tokenOptions[0].address : "");
      setFromTokenSymbol(tokenOptions.length ? tokenOptions[0].symbol : "");
    } else {
      setToChainId(chainId);
      setToChain(selectedChain.name);
      setToChainExp(selectedChain.explorer);
      setToToken(tokenOptions.length ? tokenOptions[0].address : "");
    }
  };



  const handleSwap = async (e) => {
    e.preventDefault();

    setStatusMessage("Initializing Intent request. Please wait...");

    if (!defaultAccount) {
      alert("Please connect your wallet first!");
      return;
    }
    console.log("Starting Swap...");

    if (!fromAddress || !toAddress || !amount || !fromTokenSymbol || !toToken) {
      alert("Please fill all fields.");
      return;
    }

    if (!(await checkServerAvailability())) {
      alert("MPC Service is not available. Please try again later.");
      setStatusMessage("MPC Service unavailable.");
      setRetry(true);
      setIsProcessing(false);
      return;
    }

    try {
      setRetry(false);
      setIsProcessing(true);
      console.log("Proceeding with swap...");

      const fromTokenInfo = tokens[fromChainId]?.find(token => token.symbol === fromTokenSymbol);
      const toTokenInfo = tokens[toChainId]?.find(token => token.symbol === toToken);
      const path = "0xabcdef"; // Replace with actual path hex

      const { api, nonce } = await submitTransactionIntent(
        fromAddress,
        toAddress,
        fromChainId,
        toChainId,
        amount,
        fromTokenInfo,
        toTokenInfo,
        path,
        signerAddress,
        defaultAccount
      );

      checkIntentStatus(signerAddress, path, nonce, setStatusMessage, setmpcResponceHash, setStatus, setRetry, setIsProcessing);

    } catch (error) {
      console.error("Transaction error:", error);
      setRetry(true);
      setIsProcessing(false);
      setStatusMessage(`Error: ${error.message}`);
      setStatus("error");
    }
  };

  return (
    <form onSubmit={handleSwap}>
      {" "}
      {/* Changed div to form */}
      <div className="flex flex-wrap gap-6">
        {/* From Chain, Token, and Address Fields */}
        <div className="flex-1 border p-6 rounded-lg shadow-sm bg-gray-100">
          <h3 className="text-xl font-bold mb-4 text-blue-600">Source Chain</h3>
          <SelectField
            label="Chain"
            id="from-chain"
            options={chains}
            value={fromChainId}
            onChange={(e) => handleChainChange(e, "from")}
          // disabled={true} // Disable the dropdown
          />
          <SelectField
            label="Token"
            id="from-token"
            options={tokens[fromChainId] || []}
            value={fromTokenSymbol}
            onChange={(e) => setFromTokenSymbol(e.target.value)}
          />

          <FormField
            label="Address"
            id="from-address"
            value={fromAddress}
            onChange={(e) => setFromAddress(e.target.value)}
            placeholder="Enter source address"
            disabled={true}
          />
        </div>
        {/* To Chain, Token, and Address Fields */}
        <div className="flex-1 border p-6 rounded-lg shadow-sm bg-gray-100">
          <h3 className="text-xl font-bold mb-4 text-green-600">
            Target Chain
          </h3>
          <SelectField
            label="Chain"
            id="to-chain"
            options={chains}
            value={toChainId}
            onChange={(e) => handleChainChange(e, "to")}
          />
          <SelectField
            label="Token"
            id="to-token"
            options={tokens[toChainId] || []}
            value={toToken}
            onChange={(e) => setToToken(e.target.value)}
          />
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" onChange={e => setMyaddress(!myAdress ? e.target.value : "")} />
          <label for="vehicle1"> My Address</label>
          <FormField
            label="Address"
            id="to-address"
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
            disabled={myAdress}
            placeholder="Enter destination address"
          />
        </div>
      </div>
      {/* Amount Field */}
      <div className="mt-6">
        <FormField
          label="Amount"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
        />
      </div>
      {/* Status and Buttons */}
      <StatusMessage
        // statusMessage={apiHash || statusMessage}
        statusMessage={statusMessage}
        transactionHash={transactionHash}
        mpcResponceHash={mpcResponceHash}
        fromChain={fromChain}
        toChain={toChain}
        fromChainExp={fromChainExp}
        toChainExp={toChainExp}
      />
      <div className="mt-6">
        <SwapButton isProcessing={isProcessing} status={status} retry={retry} />
      </div>
    </form>
  );

}
export default SwapForm;
