import axios from "axios";

const SERVER_API = "https://sync.abstraction.antiers.work/api/intentStatus";
const LOCAL_API = `http://172.16.15.90:9000/api/intentStatus`

export async function checkIntentStatus(signerAddress, path, nonce, setStatusMessage, setmpcResponceHash, setStatus, setRetry, setIsProcessing) {
  setStatusMessage("Transaction intent successfully submitted. Awaiting confirmation...");

  const interval = setInterval(async () => {
    try {
      const { data } = await axios.get(SERVER_API, {
        params: {
          sender: signerAddress,
          path: path,
          nonce: nonce.toString(),
        },
        timeout: 10000,
      });

      console.log("Intent status response:", data?.result);

      if (data?.result?.status === 1) {
        setStatusMessage("Transaction failed. Please review details and try again.");
        setRetry(true);
        setIsProcessing(false);
        clearInterval(interval);
        return;
      }

      if (data?.result?.apiTxHash) {
        setStatusMessage("");
        setmpcResponceHash(data?.result?.apiTxHash);
        setStatus("success");
        setIsProcessing(false);
        clearInterval(interval);
        return;
      }
    } catch (error) {
      console.error("Error checking intent status:", error);
      setStatusMessage("Error: Solvers are not available.");
      setRetry(true);
      setIsProcessing(false);
      setStatus("error");
      clearInterval(interval);
    }
  }, 8000);

  return () => clearInterval(interval);
}
